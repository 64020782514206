@import '../../../styles/settings';

.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text {
    color: $gray-900;
    margin-top: rem(10);
  }
}

.ModalLoader {
  &__icon {
    img {
      width: 60px;
      border-radius: 50%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  &__text {
      margin-top: 30px;
      margin-bottom: 30px;
  }
}
