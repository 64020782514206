@font-face {
  font-family: 'ITC Avant Garde Std';
  src: url('../assets/fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Bk.woff') format('woff');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Std';
  src: url('../assets/fonts/ITCAvantGardeStd-Md.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Md.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'ITC Avant Garde Std';
  src: url('../assets/fonts/ITCAvantGardeStd-Demi.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Demi.woff') format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'ITC Avant Garde Std';
  src: url('../assets/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

body {
  font-family: 'ITC Avant Garde Std', 'Arial', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
