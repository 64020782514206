@import '../../styles/settings';

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 2rem;
  //height: 100%;
  &__item {
    cursor:pointer;
    &--submenu {
      
      .menu__link {
        font-size: 13px;
        white-space: normal;
        html[dir='ltr'] & {
          padding: 9px 15px 9px 20px;
        }
        html[dir='rtl'] & {
          padding: 9px 20px 9px 15px;
        }
      }
    }
    &--active {
      background-color: #F2F4F4;
      > .menu__link {
        border-bottom: 1px solid #E1E7E7;
      }
      > .submenu {
        height: auto;
      }
      > .dropdown-toggle {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__link {
    text-decoration: none;
    color: $gray-900;
    display: flex;
    align-items: center;
    line-height: 20px;
    white-space: nowrap;
    html[dir='ltr'] & {
      padding: 15px 15px 15px 20px;
    }
    html[dir='rtl'] & {
      padding: 15px 20px 15px 15px;
    }
    &:hover {
      color: $primary;
    }
    &:focus {
      outline: none;
    }
  }
  &__icon {
    color: $gray-700;
    font-size: 20px;
    min-width: 23px;
    html[dir='ltr'] & {
      margin-right: 1.125rem;
    }
    html[dir='rtl'] & {
      margin-left: 1.125rem;
    }
  }

  &__text {
    position: relative;
    top: 2px;
  }
}

.submenu {
  height: 0;
  transition-duration: 0.5s;
 overflow: hidden;
  background-color: #F2F4F4;
  // position: absolute;
  // top: 0px;
  // width: 100%;
  // height: 100%;
  // background: rgb(255, 255, 255);
  // overflow-y: auto;
  &__list {
    list-style: none;
    padding: 14px 0;
    margin: 0;
  }
  &__items{
    list-style: none;
    padding-left: 10px;
    display: none;
    background: #f9f9f9;
    a{
      padding: 5px 15px 5px 20px!important;
    }
  }
}
.menu__item--submenu.menu__item--active{
  background: #f9f9f9;
  border: #e1e1e1 1px solid;
    border-width: 1px 0;
  .menu__link{
    border-bottom: 0;
  }
  .submenu__items{
    display: block;
  }

} 

.dropdown-toggle {
  white-space: nowrap;
  transition: transform 0.5s ease-in-out;
  html[dir='ltr'] & {
    padding-right: 2rem;
  }
  html[dir='rtl'] & {
    padding-left: 2rem;
  }
  &:after {
    font-family: 'icomoon' !important;
    content: '\e901';
    font-size: 0.4375rem;
    position: absolute;
    html[dir='ltr'] & {
      right: 12px;
    }
    html[dir='rtl'] & {
      left: 12px;
    }
  }
}

.sidemenu {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 282px;
  background: $white;
  align-self: start;
  position: fixed;
  top: 57px;
  height: 92vh;
  height: calc(100vh - 56px);
  transition-duration: 0.5s;
  z-index: 99;
  @media only screen and (min-width: 992px) {
    width: 62px;
    html[dir='ltr'] & {
      border-right: 1px solid $border-color;
      left: 0;
    }
    html[dir='rtl'] & {
      border-left: 1px solid $border-color;
      right: 0;
    }
    &.active {
      width: 255px;
    }
  }

  @media only screen and (max-width: 991.98px) {
    top: 0;
    z-index: 101;
    max-width: 300px;
    transition: left 0.5s ease-in-out;
    width: 100%;
    height: 100%;
    html[dir='ltr'] & {
      left: -310px;
    }
    html[dir='rtl'] & {
      right: -310px;
    }
    &.active {
      html[dir='ltr'] & {
        left: 0;
      }
      html[dir='rtl'] & {
        right: 0;
      }
    }
  }
}

.back-link {
  padding: 1rem 0.9375rem;
  border-bottom: 1px solid $border-color;
  .link {
    background: transparent;
    outline: none;
    border: none;
    user-select: none;
  }
}

.black-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  
  &.menu-drop {
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }
}

.toggle-menu-outer {
  padding: 18px 21px;
  .toggle-menu {
    width: 19px;
    height: 19px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .bar {
      width: 19px;
      height: 1px;
      background: $gray-900;
      display: inline-block;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 19px;
        height: 1px;
        background: $gray-900;
        html[dir='ltr'] & {
          right: 0;
        }
        html[dir='rtl'] & {
          left: 0;
        }
      }
      &:before {
        top: 3px;
      }
      &:after {
        top: 15px;
      }
    }

    &.active {
      .bar {
        transform: rotate(45deg);
        &:before {
          transform: rotate(90deg);
          top: 0;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

.rotate-180 svg {
  transform: rotate(180deg);
}
