@import 'settings';

.link {
  text-decoration: none;
  color: $gray-400;
  font-size: 0.9375rem;
  display: inline-flex;
  align-items: center;
  padding: 0;
  &:hover,
  &:focus {
    color: $primary;
    text-decoration: underline;
  }
  &--underline {
    text-decoration: underline;
  }
  &--primary {
    color: $primary;
  }
  &__witharrow {
    font-size: 1rem;
    color: $gray-900;
    i {
      font-size: 0.75em;
      html[dir='ltr'] & {
        margin-right: 25px;
      }
      html[dir='rtl'] & {
        margin-right: 25px;
      }
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
